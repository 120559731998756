import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

import PostLayout from '../components/postLayout';
import Seo from '../components/seo';
import { StaticPageBySlugQuery } from '../../types/graphql-types';

const StaticPageTemplate: React.FC<PageProps<StaticPageBySlugQuery>> = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || 'Title';

  return (
    <PostLayout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          {/* <!--Title--> */}
          <div className="text-center pt-16 md:pt-32">
            <h1 className="font-bold break-normal text-3xl md:text-5xl">{post.frontmatter.title}</h1>
          </div>
          {/* <!--image--> */}
          <div className="container w-full max-w-6xl mx-auto bg-white bg-cover mt-8 rounded" style={{ backgroundImage: "url('https://source.unsplash.com/collection/1118905/')", height: '20vh' }} />
        </header>

        {/* <!--Container--> */}
        <div className="container max-w-5xl mx-auto -mt-32">

          <div className="mx-0 sm:mx-6">

            <div className="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal">

              {/* <!--/ Post Content--> */}
              <section>
                <p className="text-sm md:text-base text-green-500 font-bold text-right">
                  最終更新日:
                  {' '}
                  {post.frontmatter.modifieddate}
                </p>
                <div
                  className="mt-2 text-gray-800 markdown"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                />
              </section>
            </div>

          </div>
        </div>
      </article>
    </PostLayout>
  );
};

export default StaticPageTemplate;

export const pageQuery = graphql`
  query StaticPageBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        modifieddate(formatString: "yyyy/MM/DD")
        description
      }
    }
  }
`;
